import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'loading-icon',
  styleUrl: 'loading.css',
  shadow: true,
})
export class LoadingIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      // Loading icon svg
      <div class="wave">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    );
  }
}
