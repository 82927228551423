.wave {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: var(--dot-color, white);
  border-radius: 50%;
  margin: 0 2px;
  animation: wave 1.5s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}
