import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'italic-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class ItalicIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      // Italic icon svg
      <svg width={this.width} height={this.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10 20 4-16m2 0h-4m0 16H8" />
      </svg>
    );
  }
}
