import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'microsoft-logo-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class MicrosoftLogoIcon {
  @Prop() width: string = '22';
  @Prop() height: string = '22';

  render() {
    // Spam icon svg
    return (
      <svg width={this.width} height={this.height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.043457 0.043457H10.4782V10.4782H0.043457V0.043457Z" fill="#F35325" />
        <path d="M11.5217 0.043457H21.9565V10.4782H11.5217V0.043457Z" fill="#81BC06" />
        <path d="M0.043457 11.5217H10.4782V21.9565H0.043457V11.5217Z" fill="#05A6F0" />
        <path d="M11.5217 11.5217H21.9565V21.9565H11.5217V11.5217Z" fill="#FFBA08" />
      </svg>
    );
  }
}
