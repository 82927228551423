import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'microsoft-teams-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class GoogleMeetIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    // Spam icon svg
    return (
      <svg width={this.width} height={this.height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_22_6280)">
          <path
            d="M13.7109 7.60168H18.8769C19.365 7.60168 19.7607 7.99734 19.7607 8.4854V13.191C19.7607 14.9848 18.3065 16.4389 16.5127 16.4389H16.4974C14.7036 16.4391 13.2493 14.9852 13.249 13.1914C13.249 13.1913 13.249 13.1911 13.249 13.191V8.06355C13.249 7.80847 13.4558 7.60168 13.7109 7.60168Z"
            fill="#5059C9"
          />
          <path
            d="M17.2024 6.67152C18.3583 6.67152 19.2954 5.73444 19.2954 4.5785C19.2954 3.42255 18.3583 2.48547 17.2024 2.48547C16.0465 2.48547 15.1094 3.42255 15.1094 4.5785C15.1094 5.73444 16.0465 6.67152 17.2024 6.67152Z"
            fill="#5059C9"
          />
          <path
            d="M10.6912 6.67152C12.3609 6.67152 13.7145 5.31796 13.7145 3.64826C13.7145 1.97856 12.3609 0.625 10.6912 0.625C9.02153 0.625 7.66797 1.97856 7.66797 3.64826C7.66797 5.31796 9.02153 6.67152 10.6912 6.67152Z"
            fill="#7B83EB"
          />
          <path
            d="M14.7223 7.60168H6.19482C5.71257 7.61362 5.33108 8.01384 5.34227 8.49611V13.8631C5.27492 16.7571 7.56457 19.1588 10.4586 19.2296C13.3525 19.1588 15.6422 16.7571 15.5748 13.8631V8.49611C15.586 8.01384 15.2045 7.61362 14.7223 7.60168Z"
            fill="#7B83EB"
          />
          <path
            opacity="0.1"
            d="M10.9234 7.60168V15.1226C10.9211 15.4675 10.7121 15.7773 10.3932 15.9087C10.2917 15.9516 10.1825 15.9738 10.0723 15.9738H5.75134C5.69088 15.8203 5.63507 15.6668 5.58855 15.5087C5.42574 14.975 5.34267 14.4201 5.34204 13.8622V8.49471C5.33086 8.01321 5.71172 7.61362 6.1932 7.60168H10.9234Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M10.4583 7.60168V15.5877C10.4583 15.698 10.4362 15.8071 10.3932 15.9087C10.2619 16.2276 9.95205 16.4366 9.60717 16.4389H5.96995C5.89088 16.2854 5.81646 16.1319 5.75134 15.9738C5.68623 15.8156 5.63507 15.6668 5.58855 15.5087C5.42574 14.975 5.34267 14.4201 5.34204 13.8622V8.49471C5.33086 8.01321 5.71172 7.61362 6.1932 7.60168H10.4583Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M10.4583 7.60168V14.6575C10.4548 15.1261 10.0758 15.5051 9.60716 15.5087H5.58855C5.42574 14.975 5.34267 14.4201 5.34204 13.8622V8.49471C5.33086 8.01321 5.71172 7.61362 6.1932 7.60168H10.4583Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M9.9932 7.60168V14.6575C9.98965 15.1261 9.61065 15.5051 9.14203 15.5087H5.58855C5.42574 14.975 5.34267 14.4201 5.34204 13.8622V8.49471C5.33086 8.01321 5.71172 7.61362 6.1932 7.60168H9.9932Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M10.9241 5.1971V6.66222C10.845 6.66687 10.7706 6.67152 10.6915 6.67152C10.6124 6.67152 10.538 6.66687 10.4589 6.66222C10.3019 6.6518 10.1462 6.62689 9.99382 6.5878C9.05195 6.36475 8.27382 5.70421 7.90079 4.81106C7.83661 4.66107 7.78678 4.50533 7.75195 4.34595H10.0729C10.5422 4.34772 10.9223 4.72775 10.9241 5.1971Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M10.4585 5.66219V6.66219C10.3015 6.65177 10.1458 6.62686 9.99341 6.58778C9.05155 6.36473 8.27342 5.70418 7.90039 4.81104H9.60736C10.0767 4.81281 10.4567 5.19285 10.4585 5.66219Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M10.4585 5.66219V6.66219C10.3015 6.65177 10.1458 6.62686 9.99341 6.58778C9.05155 6.36473 8.27342 5.70418 7.90039 4.81104H9.60736C10.0767 4.81281 10.4567 5.19285 10.4585 5.66219Z"
            fill="black"
          />
          <path opacity="0.2" d="M9.99341 5.6622V6.58778C9.05155 6.36473 8.27342 5.70418 7.90039 4.81104H9.14225C9.6116 4.81282 9.99163 5.19286 9.99341 5.6622Z" fill="black" />
          <path
            d="M0.613296 4.81104H9.14074C9.61159 4.81104 9.99329 5.19274 9.99329 5.66359V14.191C9.99329 14.6619 9.61158 15.0436 9.14074 15.0436H0.613296C0.14244 15.0436 -0.239258 14.6619 -0.239258 14.191V5.66359C-0.239258 5.19274 0.142449 4.81104 0.613296 4.81104Z"
            fill="url(#paint0_linear_22_6280)"
          />
          <path d="M7.12025 8.05659H5.4156V12.6985H4.32956V8.05659H2.63281V7.15613H7.12025V8.05659Z" fill="white" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_22_6280" x1="1.53835" y1="4.14486" x2="8.2157" y2="15.7098" gradientUnits="userSpaceOnUse">
            <stop stop-color="#5A62C3" />
            <stop offset="0.5" stop-color="#4D55BD" />
            <stop offset="1" stop-color="#3940AB" />
          </linearGradient>
          <clipPath id="clip0_22_6280">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
