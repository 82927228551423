@import '../../../common/styles/variables.scss';

:host {
  display: block;
  width: inherit;
  @include default-css-variables;
}

.dropdown {
  display: inline-block;
  width: 100%;
  position: relative;

  .dropdown-label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--nylas-base-800);
  }

  span.error {
    color: var(--nylas-error);
    font-size: 14px;
  }
}

.dropbtn {
  width: inherit;
  height: 48px;
  color: black;
  padding: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: 1px solid var(--nylas-base-200);
  border-radius: var(--nylas-border-radius-2x);

  &.focus {
    background: transparent;
  }

  &.error {
    border: 1px solid var(--nylas-error);
  }

  &:hover,
  &:active {
    border: 1px solid var(--nylas-primary);
  }

  &:active {
    outline: 2px solid var(--nylas-primary);
  }

  span {
    &.open {
      transform: rotate(90deg);
    }

    &.closed {
      transform: rotate(270deg);
    }
  }
}

.dropdown-content {
  display: block;
  margin-top: 0.5rem;
  background-color: var(--nylas-base-0);
  width: 100%;
  max-height: 336px;
  overflow: auto;
  z-index: 1;
  border-radius: 4px;
  position: absolute;
  top: calc(48px + 24px);
  box-shadow: 0px 4px 6px -2px #0000000d;
  box-shadow: 0px 10px 15px -3px #0000001a;
}

.dropdown-content ul {
  padding: 0;
  list-style-type: none;
  color: var(--nylas-base-900);
  max-height: 336px;

  li {
    padding: 16px, 12px, 16px, 12px;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--nylas-base-100);
    }

    label {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      input {
        margin: 0;
      }
    }
  }
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem 0;
  margin-top: 0.25rem;
  background: var(--nylas-base-0);

  .selected-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 4px 8px;
    border-radius: var(--nylas-border-radius-2x);
    background: var(--nylas-base-100);
    color: var(--nylas-base-800);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.5px;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;

      &:hover {
        color: var(--nylas-primary);
      }
    }
  }
}
