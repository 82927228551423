import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'location-off-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class LocationOffIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '18';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.146447 0.808556C0.341709 0.613294 0.658291 0.613294 0.853553 0.808556L15.8536 15.8086C16.0488 16.0038 16.0488 16.3204 15.8536 16.5157C15.6583 16.7109 15.3417 16.7109 15.1464 16.5157L12.0938 13.463L11.8223 13.7312L9.38611 16.1012C8.65623 16.8076 7.52168 16.8468 6.74691 16.2188L6.61408 16.101L4.57128 14.1159L3.05025 12.6171C0.607421 10.1732 0.347525 6.37239 2.27056 3.63976L0.146447 1.51566C-0.0488155 1.3204 -0.0488155 1.00382 0.146447 0.808556ZM9.07768 10.4469C8.74346 10.576 8.38024 10.6468 8.0005 10.6468C6.34839 10.6468 5.00909 9.30689 5.00909 7.65406C5.00909 7.27461 5.07967 6.91166 5.20843 6.57763L2.98793 4.35713C1.49206 6.62038 1.68917 9.67377 3.57927 11.7288L3.75499 11.912L4.3263 12.4791L7.30713 15.3845L7.40012 15.4642C7.7543 15.732 8.24594 15.732 8.60016 15.4642L8.69315 15.3846L10.9237 13.216L11.3883 12.7575L9.07768 10.4469ZM9.85409 8.39486L10.5983 9.13904C10.8487 8.70143 10.9919 8.19447 10.9919 7.65406C10.9919 6.00122 9.65261 4.66134 8.0005 4.66134C7.45986 4.66134 6.95271 4.80482 6.51504 5.05581L7.25921 5.79998C7.48839 5.70819 7.73855 5.6577 8.0005 5.6577C9.10258 5.6577 9.99599 6.5515 9.99599 7.65406C9.99599 7.91581 9.94564 8.16581 9.85409 8.39486ZM4.35291 2.89368C6.70671 1.08827 10.0908 1.26314 12.245 3.4183C14.3986 5.57283 14.5739 8.95702 12.771 11.3118L13.4812 12.022C15.6702 9.27273 15.493 5.25762 12.9497 2.71326C10.4058 0.168159 6.39081 -0.00843871 3.64269 2.18347L4.35291 2.89368Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
