import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'location-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class LocationIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '18';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 7.66211C10 9.31896 8.65685 10.6621 7 10.6621C5.34315 10.6621 4 9.31896 4 7.66211C4 6.00526 5.34315 4.66211 7 4.66211C8.65685 4.66211 10 6.00526 10 7.66211ZM9 7.66211C9 6.55754 8.10457 5.66211 7 5.66211C5.89543 5.66211 5 6.55754 5 7.66211C5 8.76668 5.89543 9.66211 7 9.66211C8.10457 9.66211 9 8.76668 9 7.66211ZM11.9497 12.6171C14.6834 9.88221 14.6834 5.44812 11.9497 2.71326C9.21608 -0.0216067 4.78392 -0.0216067 2.05025 2.71326C-0.683418 5.44812 -0.683418 9.88221 2.05025 12.6171L3.57128 14.1159L5.61408 16.101L5.74691 16.2188C6.52168 16.8468 7.65623 16.8076 8.38611 16.1012L10.8223 13.7312L11.9497 12.6171ZM2.75499 3.4183C5.09944 1.07282 8.90055 1.07282 11.245 3.4183C13.5294 5.70364 13.5879 9.3725 11.4207 11.7288L11.245 11.912L9.92371 13.216L7.69315 15.3846L7.60016 15.4642C7.24594 15.732 6.7543 15.732 6.40012 15.4642L6.30713 15.3845L3.3263 12.4791L2.75499 11.912L2.57927 11.7288C0.412077 9.3725 0.47065 5.70364 2.75499 3.4183Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
