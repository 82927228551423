import { debug } from '@/utils/utils';
import { Component, Element, Event, EventEmitter, Host, Listen, Prop, State, h } from '@stencil/core';

interface DropdownOption {
  label: string;
  value: string;
  selected?: boolean; // Add a selected flag to each option
}

@Component({
  tag: 'multi-select-dropdown',
  styleUrl: 'multi-select-dropdown.scss',
  shadow: true,
})
export class MultiSelectDropdown {
  @Element() el!: HTMLElement;

  // Props
  /**
   * The name of the dropdown
   */
  @Prop() name!: string;

  /**
   * The label of the dropdown
   */
  @Prop() label?: string;

  /**
   * The options to display in the dropdown
   */
  @Prop() options: DropdownOption[] = [];

  /**
   * Error message to display
   */
  @Prop() error?: string = '';

  // States
  /**
   * The copy of the options to display in the dropdown
   */
  @State() availableOptions: DropdownOption[] = this.options;
  /**
   * The open state of the dropdown
   */
  @State() isOpen: boolean = false;
  /**
   * The aria-activedescendant attribute for the listbox element to indicate the currently active
   */
  @State() ariaActivedescendant: string = '';

  /**
   * This flag is used to focus the first option when the dropdown is opened
   * and reset after the first option is focused
   */
  @State() shouldFocusFirstOption: boolean = false;

  // Events
  /**
   * This event is fired when the selected options are changed
   */
  @Event({ bubbles: true, composed: true }) selectedOptionsChanged!: EventEmitter<{
    value: string[];
    name: string;
  }>;

  // Lifecycle methods
  componentDidLoad() {
    debug('multi-select-dropdown', 'componentDidLoad');
    if (this.options) {
      this.availableOptions = this.options;
    }
  }

  componentDidRender() {
    debug('multi-select-dropdown', 'componentDidRender');
    if (this.isOpen && this.shouldFocusFirstOption) {
      // The dropdown is open and we should focus the first option
      this.ariaActivedescendant = this.availableOptions[0]?.value;
      this.focusOption(0);
      // Reset the flag
      this.shouldFocusFirstOption = false;
    }
  }

  // Event listeners
  @Listen('click', { target: 'document', capture: true })
  handleOutsideClick(event: MouseEvent) {
    // Get the path of the event
    const path = event.composedPath();

    // Check if the path includes the host element
    const isClickInside = path.includes(this.el);

    if (!isClickInside && this.isOpen) {
      this.isOpen = false;
    }
  }

  // Methods

  selectOption(option: DropdownOption): void {
    this.availableOptions = this.availableOptions.map(o => {
      if (o.value === option.value) {
        o.selected = option.selected ? false : true;
      }
      return o;
    });
    const selectedOptions = this.availableOptions.filter(o => o.selected).map(o => o.value);
    this.selectedOptionsChanged.emit({
      value: selectedOptions,
      name: this.name,
    });
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.shouldFocusFirstOption = true;
    } else {
      this.ariaActivedescendant = '';
    }
  }

  handleSelectButtonKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowDown':
      case 'Enter':
        event.preventDefault();
        if (!this.isOpen) {
          this.toggleDropdown();
        }
        break;
      case 'Escape':
        this.isOpen = false;
        break;
    }
  }

  handleListboxKeydown(e: KeyboardEvent) {
    const items = this.availableOptions;
    const currentIndex = items.findIndex(item => item.value === this.ariaActivedescendant);

    switch (e.key) {
      case 'ArrowDown':
      case 'Tab':
        if (!e.shiftKey) {
          e.preventDefault();
          const nextIndex = currentIndex + 1 < items.length ? currentIndex + 1 : 0;
          this.ariaActivedescendant = items[nextIndex].value;
          this.focusOption(nextIndex);
        } else {
          e.preventDefault();
          const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : items.length - 1;
          this.ariaActivedescendant = items[prevIndex].value;
          this.focusOption(prevIndex);
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : items.length - 1;
        this.ariaActivedescendant = items[prevIndex].value;
        this.focusOption(prevIndex);
        break;
      case 'Enter':
        e.preventDefault();
        if (this.ariaActivedescendant) {
          this.selectOption(items[currentIndex]);
        }
        break;
      case 'Escape':
        this.isOpen = false;
        break;
    }
  }

  focusOption(index: number) {
    const option = this.availableOptions[index];
    if (!option) return; // Guard clause in case index is out of bounds

    const elementId = option.value;
    const element = this.el.shadowRoot?.getElementById(elementId) as HTMLLIElement;

    if (element) {
      element.focus(); // Set focus on the element
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  getSelectedOptions() {
    return this.availableOptions.filter(option => option.selected);
  }

  renderOption(option: DropdownOption) {
    return (
      <li
        key={option.value}
        id={option.value}
        role="option"
        tabindex="0"
        aria-selected={option.selected ? 'true' : 'false'}
        onClick={e => {
          e.stopImmediatePropagation();
          this.selectOption(option);
        }}
        class={{ selected: !!option.selected }}
      >
        <label htmlFor={option.value}>
          <input aria-hidden="true" id={option.value} type="checkbox" checked={option.selected} />
          <span>{option.label}</span>
        </label>
      </li>
    );
  }

  render() {
    return (
      <Host>
        <div class="dropdown" part="msd_dropdown">
          <label class="dropdown-label">
            {this.label}
            <slot name="label-icon" aria-hidden="true"></slot>
          </label>
          <button
            name={this.name}
            part={`msd_dropdown-button ${this.error ? 'msd_dropdown-button--error' : ''}`}
            class={{ dropbtn: true, open: this.isOpen, error: !!this.error }}
            onClick={() => this.toggleDropdown()}
            aria-haspopup="listbox"
            aria-expanded={this.isOpen ? 'true' : 'false'}
            aria-label={this.name}
            onKeyDown={e => this.handleSelectButtonKeyDown(e)}
          >
            <slot name="select-icon" aria-hidden="true"></slot>
            <span class="selected-option" part="msd_dropdown-button-selected-label">
              {this.getSelectedOptions().length > 1 ? `Multiple options selected` : this.availableOptions.filter(o => o.selected)[0]?.label ?? this.availableOptions[0]?.label}
            </span>
            <span class={this.isOpen ? 'open' : 'closed'} aria-hidden="true">
              <chevron-icon width="16" height="16" />
            </span>
          </button>
          {this.error ? <span class="error">{this.error}</span> : null}
          {this.isOpen ? null : (
            <div class={'selected-options'}>
              {this.getSelectedOptions().map(option => (
                <span class="selected-option">
                  {option.label}
                  <button key={option.label} onClick={() => this.selectOption(option)}>
                    <close-icon />
                  </button>
                </span>
              ))}
            </div>
          )}
          {this.isOpen ? (
            <div class="dropdown-content" part="msd_dropdown-content">
              <ul
                tabindex="-1"
                role="listbox"
                aria-label={this.name}
                aria-multiselectable={true}
                aria-activedescendant={this.ariaActivedescendant}
                onKeyDown={e => this.handleListboxKeydown(e)}
              >
                {this.availableOptions.map(option => this.renderOption(option))}
              </ul>
            </div>
          ) : null}
        </div>
      </Host>
    );
  }
}
